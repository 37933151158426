import { Skeleton } from "primereact/skeleton";
import React from "react";

function ViewProjectSkeleton() {
  return (
    <div>
      <div className="row">
        <div className="col-md-8">
          <Skeleton height="4rem"></Skeleton>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-6">
          <Skeleton height="2rem"></Skeleton>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-6">
          <div className="row mt-3">
            <div className="col-md-4">
              <Skeleton height="5rem"></Skeleton>
            </div>
            <div className="col-md-4">
              <Skeleton height="5rem"></Skeleton>
            </div>
            <div className="col-md-4">
              <Skeleton height="5rem"></Skeleton>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-md-12">
          <Skeleton height="25rem"></Skeleton>
        </div>
      </div>
    </div>
  );
}

export default ViewProjectSkeleton;
