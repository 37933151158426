import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button, Form } from "react-bootstrap";
import { Dropdown } from "primereact/dropdown";
import protectedAPI from "../../config/protectedAPI";
import { useToastProvider } from "../../providers/useToastProvider";
import { InputText } from "primereact/inputtext";
import { useMenuProvider } from "../../providers/useMenuProvider";
import { InputSwitch } from "primereact/inputswitch";

function NewMenuLIst(props) {
  const { show, toggle, getMenuLists, isUpdate, selectedMenuList } = props;
  const showToast = useToastProvider();
  const { getMenuItems } = useMenuProvider();

  const [menuList, setMenuList] = useState("");
  const [url, setUrl] = useState("");
  const [order, setOrder] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isMenuItem, setIsMenuItem] = useState(true);

  const menuListRef = useRef(null);
  const urlRef = useRef(null);
  const orderRef = useRef(null);
  const isMenuItemRef = useRef(null);
  const saveBtnRef = useRef(null);

  const saveMenuList = async () => {
    try {
      saveBtnRef.current.disabled = true;
      saveBtnRef.current.innerText = "Please wait...";

      if (!selectedCategory) {
        showToast({
          type: "warn",
          detail: "Please select a category.",
        });
        return;
      }

      const _url = isUpdate
        ? "settings/menuList/update/" + selectedMenuList.id
        : "settings/menuList/create";
      const response = await protectedAPI.post(_url, {
        categoryId: selectedCategory.id,
        name: menuList,
        url: url || null,
        order: order || 0,
        isMenuItem: isMenuItem ? 1 : 0,
      });
      if (response.status === 201 || response.status === 200) {
        getMenuItems();
        getMenuLists();
        showToast({
          type: "success",
          detail: response.data.message,
        });
        clearFields();
        toggle();
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    } finally {
      saveBtnRef.current.disabled = false;
      saveBtnRef.current.innerText = "Save";
    }
  };

  const getCategories = async () => {
    try {
      const response = await protectedAPI.get("settings/menuCategory/all");
      if (response.status === 200) {
        setCategories(response.data);
        if (isUpdate && selectedMenuList) {
          setSelectedCategory(
            response.data.find(
              (category) => category.id === selectedMenuList.categoryId
            )
          );
        }
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };

  const clearFields = () => {
    setSelectedCategory(null);
    setMenuList("");
    setUrl("");
    setOrder("");
    setIsMenuItem(true);
  };

  useEffect(() => {
    async function loadData() {
      await getCategories();
      if (isUpdate && selectedMenuList) {
        setMenuList(selectedMenuList.name);
        setUrl(selectedMenuList.url || "");
        setOrder(selectedMenuList.order || "");
        setIsMenuItem(selectedMenuList.isMenuItem == 1 ? true : false);
      } else {
        clearFields();
      }
    }
    loadData();
  }, [isUpdate, selectedMenuList]);

  return (
    <>
      <Dialog
        header={isUpdate ? "Update Menu Item Details" : "New Menu Item"}
        visible={show}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => {
          toggle();
        }}
      >
        <div className="row">
          <div className="col-md-12">
            <div className="d-flex flex-column mb-3">
              <Form.Label htmlFor="menuCategory">Category Name</Form.Label>
              <Dropdown
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.value)}
                options={categories}
                filter
                optionLabel="name"
                placeholder="Select a Category"
                className="w-full md:w-14rem"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <Form.Group className="d-flex flex-column mb-3">
              <Form.Label htmlFor="menuList">Menu List Name</Form.Label>
              <InputText
                id="menuList"
                ref={menuListRef}
                value={menuList}
                onChange={(e) => setMenuList(e.target.value)}
              />
            </Form.Group>
          </div>
          <div className="col-md-4">
            <Form.Group className="d-flex flex-column mb-3">
              <Form.Label htmlFor="isMenuItem">Show as a Menu Item</Form.Label>
              <InputSwitch
                id="isMenuItem"
                ref={isMenuItemRef}
                checked={isMenuItem}
                onChange={(e) => setIsMenuItem(e.value)}
              />
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <Form.Group className="d-flex flex-column mb-3">
              <Form.Label htmlFor="url">Url</Form.Label>
              <InputText
                id="url"
                ref={urlRef}
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
            </Form.Group>
          </div>
          <div className="col-md-4">
            <Form.Group className="d-flex flex-column mb-3">
              <Form.Label htmlFor="order">Order</Form.Label>
              <InputText
                id="order"
                ref={orderRef}
                value={order}
                onChange={(e) => setOrder(e.target.value)}
              />
            </Form.Group>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12 d-flex justify-content-end">
            <Button
              variant="info"
              className="p-2 px-4"
              ref={saveBtnRef}
              onClick={saveMenuList}
            >
              Save
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default NewMenuLIst;
