import { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import MenuBar from "../../components/Menubar/MenuBar";
import { useNavigate, useParams } from "react-router-dom";
import { useToastProvider } from "../../providers/useToastProvider";
import protectedAPI from "../../config/protectedAPI";
import { Card } from "primereact/card";
import { useConfirmDialogProvider } from "../../providers/useConfirmDialogProvider";
import ViewProjectSkeleton from "../../components/Skeleton/ViewProjectSkeleton";
import { TabMenu } from "primereact/tabmenu";
import { AvatarGroup } from "primereact/avatargroup";
import { Avatar } from "primereact/avatar";
import { UPLOADS_URL } from "../../config/api";
import { Skeleton } from "primereact/skeleton";
import { Message } from "primereact/message";
import ViewAssignees from "../../components/MasterFiles/ViewAssignees";
import SEVERITY_OPTIONS from "../../config/severityOptions";

const ViewProject = () => {
  const navigate = useNavigate();
  const showToast = useToastProvider();
  const showConfirmDialog = useConfirmDialogProvider();

  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [project, setProject] = useState(null);
  const [modules, setModules] = useState([]);
  const [modulesLoading, setModulesLoading] = useState(false);
  const [testCases, setTestCases] = useState([]);
  const [testCasesLoading, setTestCasesLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState(0);
  const [selectedModule, setSelectedModule] = useState(null);

  const items = [
    { label: "General", icon: "pi pi-info-circle" },
    { label: "Modules", icon: "pi pi-chart-line" },
    { label: "Testcases", icon: "pi pi-list" },
  ];

  // View Assignees
  const [assingeeDialogHeader, setAssigneeDialogHeader] = useState("");
  const [viewAssigneeDialogVisible, setViewAssigneesDialogVisible] =
    useState(false);
  const [viewAssigneeDialogAssignees, setViewAssigneeDialogAssignees] =
    useState([]);

  const getProject = async () => {
    try {
      setIsLoading(true);
      const response = await protectedAPI.get("project/get/" + id);
      if (response.status === 200) {
        setProject(response.data);
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again. ",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getModules = async (_selectedProject) => {
    try {
      setModulesLoading(true);
      const response = await protectedAPI.get(
        "module/all?projectId=" + _selectedProject?.id || 0
      );
      if (response.status === 200) {
        setModules(response.data);
      }
    } catch (error) {
      setModules([]);
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    } finally {
      setModulesLoading(false);
    }
  };

  const getTestCases = async (_selectedProject) => {
    const _selectedModule = selectedModule;
    const _selectedSeverity = 0;
    try {
      setTestCasesLoading(true);
      const response = await protectedAPI.get(
        "testcase/all?moduleId=" +
          (_selectedModule?.id || 0) +
          "&severity=" +
          (_selectedSeverity || 0) +
          "&projectId=" +
          (_selectedProject?.id || 0)
      );
      if (response.status === 200) {
        // console.log(response.data);
        setTestCases(response.data);
      }
    } catch (error) {
      setTestCases([]);
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    } finally {
      setTestCasesLoading(false);
    }
  };

  const moduleTesterTemplate = (module) => {
    if (module) {
      return (
        <>
          <div className="d-flex mt-1">
            <small>
              {module?.moduleTesters && module?.moduleTesters?.length > 0 ? (
                <AvatarGroup>
                  {module.moduleTesters.map(
                    (manager, key) =>
                      key < 2 && (
                        <Avatar
                          key={key}
                          image={
                            UPLOADS_URL +
                            "employees/" +
                            (manager.userDetails.image || "no-user.png")
                          }
                          size="small"
                          shape="circle"
                          onClick={() => {
                            setAssigneeDialogHeader(`Assigned Module Testers`);
                            setViewAssigneeDialogAssignees(
                              module.moduleTesters
                            );
                            setViewAssigneesDialogVisible(true);
                          }}
                        />
                      )
                  )}
                  {module.moduleTesters.length > 2 && (
                    <Avatar
                      label={`+${module.moduleTesters.length - 2}`}
                      shape="circle"
                      size="small"
                      onClick={() => {
                        setAssigneeDialogHeader(`Assigned Module Testers`);
                        setViewAssigneeDialogAssignees(module.moduleTesters);
                        setViewAssigneesDialogVisible(true);
                      }}
                    />
                  )}
                </AvatarGroup>
              ) : (
                <>
                  <small className="text-muted">
                    <i className="bi bi-info-circle me-1"></i> No Testers
                    Assigned.
                  </small>
                </>
              )}
            </small>
          </div>
        </>
      );
    }
  };

  const modulesTestCasesTemplate = (module) => {
    return (
      <>
        <div className="d-flex">
          <span
            className="badge bg-warning-subtle text-warning-emphasis border border-warning-subtle fw-normal me-3"
            style={{ height: "fit-content" }}
          >
            {module.testCasesCount || 0} Test Cases
          </span>
          <span
            className="badge bg-success-subtle text-success-emphasis border border-success-subtle fw-normal"
            style={{ height: "fit-content" }}
          >
            {module.progress?.acceptedTestCases || 0} Approved
          </span>
        </div>
      </>
    );
  };

  const severityOptions = SEVERITY_OPTIONS;

  const severityDropdownTdTemplate = (option) => {
    const _severity = severityOptions.find(
      (status) => status?.value == option.severity
    );
    return (
      <span
        className="rounded px-2"
        style={{
          fontSize: "12px",
          backgroundColor: _severity?.color + "50",
        }}
      >
        {_severity?.label || "Unknown"}
      </span>
    );
  };

  useEffect(() => {
    getProject();
  }, [id]);

  useEffect(() => {
    if (project && activeTabKey === 0) {
      getModules(project);
    }
    if (project && activeTabKey === 1) {
      getModules(project);
    }
    if (project && activeTabKey === 2) {
      getTestCases(project);
    }
  }, [activeTabKey]);

  useEffect(() => {
    const _user = JSON.parse(localStorage.getItem("user"));
    setUser(_user);
  }, []);

  return (
    <div className="d-flex main-content">
      <MenuBar />
      <div className="d-flex flex-column page">
        <Header pageTitle="Project Details" />
        <div className="d-flex flex-column p-4">
          {isLoading ? (
            <>
              <ViewProjectSkeleton />
            </>
          ) : (
            <>
              <div className="d-flex align-items-center">
                <div className="me-4">
                  <button
                    className="btn border"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <i className="pi pi-arrow-left"></i>
                  </button>
                </div>
                <div className="d-flex flex-column">
                  <h1 className="fw-bold text-info pt-2">
                    {project?.title ? project?.title : "Unknown Project"}
                  </h1>
                  <div className="d-flex">
                    {/* <span className="fw-bold me-2">Project Code : </span> */}
                    <span
                      className="badge text-bg border border-dark-subtle fw-bold"
                      style={{ width: "fit-content" }}
                    >
                      {project?.code}
                    </span>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <Card>
                  <TabMenu
                    model={items}
                    activeIndex={activeTabKey}
                    onTabChange={(e) => setActiveTabKey(e.index)}
                  />
                  {activeTabKey === 0 && (
                    <>
                      <div className="d-flex flex-column">
                        <div className="row mt-2">
                          <div className="col-12 col-sm-3 mb-3 mb-sm-0">
                            <div className="card bg-success-subtle w-100">
                              <div className="card-body">
                                <h6 className="card-title">Total Modules</h6>
                                <div className="d-flex justify-content-between align-items-center">
                                  <h2 className="card-text fw-bold">
                                    {project?.modulesCount || 0}
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-3 mb-3 mb-sm-0">
                            <div className="card bg-primary-subtle w-100">
                              <div className="card-body">
                                <h6 className="card-title">Total Test Cases</h6>
                                <div className="d-flex justify-content-between align-items-center">
                                  <h2 className="card-text fw-bold">
                                    {project?.progress?.totalTestCases || 0}
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-3 mb-3 mb-sm-0">
                            <div className="card bg-warning-subtle w-100">
                              <div className="card-body">
                                <h6 className="card-title">
                                  Approved Test Cases
                                </h6>
                                <div className="d-flex justify-content-between align-items-center">
                                  <h2 className="card-text fw-bold">
                                    {project?.progress?.acceptedTestCases || 0}
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-3 mb-3 mb-sm-0">
                            <div className="card bg-danger-subtle w-100">
                              <div className="card-body">
                                <h6 className="card-title">Project Progress</h6>
                                <div className="d-flex justify-content-between align-items-center">
                                  <h2 className="card-text fw-bold">
                                    {parseFloat(project?.progress?.progress || 0).toFixed(0)}%
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex flex-column mt-3">
                          <small className="text-info">Description</small>
                          <p className="mt-2">{project?.description}</p>
                        </div>
                        <div className="d-flex flex-column mt-2">
                          <small className="text-info">Client</small>
                          <p className="mt-2">
                            {project?.clientName || "Unknown"}
                          </p>
                        </div>
                        <div className="d-flex flex-column mt-2">
                          <div className="d-flex">
                            <div className="me-5">
                              <small className="text-info">Start Date</small>
                              <p className="mt-2">
                                {project?.startDate || "-"}
                              </p>
                            </div>
                            <div>
                              <small className="text-info">
                                Expected End Date
                              </small>
                              <p className="mt-2">
                                {project?.expectedEndDate || "-"}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex flex-column mt-2">
                          <small className="text-info">Project Managers</small>
                          <div className="d-flex flex-column mt-2">
                            {project?.projectManagers?.map(
                              (assignee, index) => (
                                <div className="d-flex mt-3" key={index}>
                                  <div className="me-4">
                                    <Avatar
                                      key={index}
                                      image={
                                        UPLOADS_URL +
                                        "employees/" +
                                        (assignee.userDetails.image ||
                                          "no-user.png")
                                      }
                                      size="large"
                                      shape="circle"
                                    />
                                  </div>
                                  <div className="d-flex flex-column">
                                    <div>
                                      <span className="fw-bold">
                                        {assignee.userDetails.fullname ||
                                          assignee.userDetails.nickname}
                                      </span>
                                    </div>
                                    <div className="d-flex">
                                      <small className="me-4">
                                        Email :{" "}
                                        {assignee.userDetails.email || "-"}
                                      </small>
                                      <small>
                                        Mobile:{" "}
                                        {assignee.userDetails.mobile || "-"}
                                      </small>
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                            {project?.projectManagers?.length === 0 && (
                              <div className="d-flex">
                                <small className="text-muted">
                                  No Project Managers assigned.
                                </small>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {activeTabKey === 1 && (
                    <>
                      {modulesLoading && (
                        <div className="d-flex flex-column">
                          <Skeleton height="70px" className="mt-3" />
                          <Skeleton
                            height="20px"
                            width="85%"
                            className="mt-1"
                          />
                          <Skeleton height="70px" className="mt-3" />
                          <Skeleton
                            height="20px"
                            width="50%"
                            className="mt-1"
                          />
                          <Skeleton height="70px" className="mt-3" />
                          <Skeleton
                            height="20px"
                            width="65%"
                            className="mt-1"
                          />
                        </div>
                      )}
                      {!modulesLoading && (
                        <>
                          {modules.length === 0 && (
                            <div className="alert alert-danger" role="alert">
                              <span>No modules found for this project</span>
                            </div>
                          )}
                          {modules.length > 0 && (
                            <>
                              <div className="d-flex flex-column">
                                <Message
                                  style={{
                                    border: "solid #696cff",
                                    borderWidth: "0 0 0 6px",
                                  }}
                                  className="border-primary w-full justify-content-start mb-3"
                                  severity="info"
                                  text={
                                    (modules?.length || 0) + " Modules found."
                                  }
                                />
                                {modules.map((module, index) => (
                                  <div
                                    className="alert border border-secondary-subtle d-flex view-project-alert-item"
                                    key={index}
                                  >
                                    <div className="d-flex flex-column">
                                      <div>
                                        <span className="fw-bold">
                                          <span className="fw-normal text-muted">
                                            {index + 1 + ". "}
                                          </span>
                                          {module.title}
                                        </span>
                                      </div>
                                      <div className="d-flex">
                                        <small className="text-muted me-4">
                                          {module.description ||
                                            "No description"}
                                        </small>
                                      </div>
                                      <div className="d-flex mt-2">
                                        {modulesTestCasesTemplate(module)}
                                      </div>
                                      <div className="d-flex mt-2">
                                        {moduleTesterTemplate(module)}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {activeTabKey === 2 && (
                    <>
                      {testCasesLoading && (
                        <div className="d-flex flex-column">
                          <Skeleton height="70px" className="mt-3" />
                          <Skeleton
                            height="20px"
                            width="85%"
                            className="mt-1"
                          />
                          <Skeleton height="70px" className="mt-3" />
                          <Skeleton
                            height="20px"
                            width="50%"
                            className="mt-1"
                          />
                          <Skeleton height="70px" className="mt-3" />
                          <Skeleton
                            height="20px"
                            width="65%"
                            className="mt-1"
                          />
                        </div>
                      )}
                      {!testCasesLoading && (
                        <>
                          {testCases.length === 0 && (
                            <div className="alert alert-danger" role="alert">
                              <span>No test cases found for this project</span>
                            </div>
                          )}
                          {testCases.length > 0 && (
                            <>
                              <div className="d-flex flex-column">
                                <Message
                                  style={{
                                    border: "solid #696cff",
                                    borderWidth: "0 0 0 6px",
                                  }}
                                  className="border-primary w-full justify-content-start mb-3"
                                  severity="info"
                                  text={
                                    (testCases?.length || 0) +
                                    " Test cases found."
                                  }
                                />
                                {testCases.map((testCases, index) => (
                                  <div
                                    className="alert border border-secondary-subtle d-flex view-project-alert-item"
                                    key={index}
                                  >
                                    <div className="d-flex flex-column">
                                      <div>
                                        <span className="fw-bold">
                                          <span className="fw-normal text-muted">
                                            {index + 1 + ". "}
                                          </span>
                                          {testCases?.possibleRisk !== "" &&
                                          testCases?.possibleRisk !== null
                                            ? testCases?.possibleRisk
                                            : "Unknown Risk"}
                                        </span>
                                      </div>
                                      <div className="d-flex flex-column mt-2">
                                        <small className="me-4">
                                          Test Ref:{" "}
                                          <small
                                            className="badge text-bg border border-dark-subtle fw-bold user-select-auto"
                                            style={{ width: "fit-content" }}
                                          >
                                            {testCases?.ref}
                                          </small>
                                        </small>
                                        <small className="me-4">
                                          Manager Approved:{" "}
                                          {testCases?.managerAccepted == 1
                                            ? "Yes"
                                            : "No"}
                                        </small>
                                        <small className="me-4">
                                          Director Approved:{" "}
                                          {testCases?.directorAccepted == 1
                                            ? "Yes"
                                            : "No"}
                                        </small>
                                        <small className="me-4">
                                          Severity:{" "}
                                          {severityDropdownTdTemplate(
                                            testCases
                                          )}
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Card>
              </div>
            </>
          )}
        </div>
      </div>
      <ViewAssignees
        header={assingeeDialogHeader}
        viewAssgineeDialogVisible={viewAssigneeDialogVisible}
        setViewAssigneesDialogVisible={setViewAssigneesDialogVisible}
        assignees={viewAssigneeDialogAssignees}
      />
    </div>
  );
};

export default ViewProject;
