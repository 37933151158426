import React, { createContext, useContext, useState, useEffect } from "react";

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [appTheme, setAppTheme] = useState(() => {
    return localStorage.getItem("appTheme") || "light";
  });

  useEffect(() => {
    const themeLink = document.getElementById("theme-link");
    if (themeLink) {
      const themeName =
        appTheme === "light" ? "lara-light-blue" : "lara-dark-blue";
      themeLink.href = `/css/${themeName}/theme.css`;
    }

    const rootElement = document.documentElement;
    rootElement.setAttribute("data-bs-theme", appTheme);

    if (appTheme === "dark") {
      rootElement.classList.add("app-dark-theme");
    } else {
      rootElement.classList.remove("app-dark-theme");
    }

    localStorage.setItem("appTheme", appTheme);
  }, [appTheme]);

  const toggleTheme = () => {
    setAppTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  return (
    <ThemeContext.Provider value={{ appTheme, toggleTheme }}>
      <link
        id="theme-link"
        rel="stylesheet"
        href={`https://unpkg.com/primereact/resources/themes/${
          appTheme === "light" ? "lara-light-blue" : "lara-dark-blue"
        }/theme.css`}
      />
      {children}
    </ThemeContext.Provider>
  );
};

export const useThemeProvider = () => useContext(ThemeContext);
