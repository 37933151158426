import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button, Form } from "react-bootstrap";
import protectedAPI from "../../config/protectedAPI";
import { useToastProvider } from "../../providers/useToastProvider";
import { InputText } from "primereact/inputtext";

function NewRiskCat(props) {
  const { show, toggle, getRiskCat, isUpdate, selectedRiskCat } = props;
  const showToast = useToastProvider();

  const [title, setTitle] = useState("");

  const titleRef = useRef(null);
  const saveBtnRef = useRef(null);

  const saveRiskCat = async () => {
    try {
      if (!title) {
        showToast({
          type: "warn",
          detail: "Title is required.",
        });
        return;
      }

      saveBtnRef.current.disabled = true;
      saveBtnRef.current.innerText = "Please wait...";

      const url = isUpdate
        ? "riskCategory/update/" + selectedRiskCat.id
        : "riskCategory/create";

      const payload = {
        title: title,
      };

      const response = await protectedAPI.post(url, payload);

      if (response.status === 201 || response.status === 200) {
        getRiskCat();
        showToast({
          type: "success",
          detail: response.data.message,
        });
        clearFields();
        toggle();
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    } finally {
      saveBtnRef.current.disabled = false;
      saveBtnRef.current.innerText = "Save";
    }
  };

  const clearFields = () => {
    setTitle("");
  };

  useEffect(() => {
    if (isUpdate && selectedRiskCat) {
      setTitle(selectedRiskCat.title);
    } else {
      clearFields();
    }
  }, [isUpdate, selectedRiskCat]);

  return (
    <>
      <Dialog
        header={isUpdate ? "Update Risk Category Details" : "New Risk Category"}
        visible={show}
        style={{ width: "25vw" }}
        breakpoints={{ "960px": "50vw", "641px": "100vw" }}
        onHide={() => {
          toggle();
        }}
      >
        <div className="row">
          <div className="col-md-12">
            <Form.Group className="d-flex flex-column mb-3">
              <Form.Label htmlFor="title">Title</Form.Label>
              <InputText
                id="title"
                ref={titleRef}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12 d-flex justify-content-end">
            <Button
              variant="info"
              className="p-2 px-4"
              ref={saveBtnRef}
              onClick={saveRiskCat}
            >
              Save
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default NewRiskCat;
