import { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import MenuBar from "../../components/Menubar/MenuBar";
import { useToastProvider } from "../../providers/useToastProvider";
import { Button } from "react-bootstrap";
import protectedAPI from "../../config/protectedAPI";
import CustomDataTable from "../../components/Tables/CustomDataTable";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputSwitch } from "primereact/inputswitch";
import NewUserAccount from "../../components/Settings/NewUserAccount";
import { Card } from "primereact/card";
import { useConfirmDialogProvider } from "../../providers/useConfirmDialogProvider";

function UserAccount() {
  // const navigate = useNavigate();
  const showToast = useToastProvider();
  const showConfirmDialog = useConfirmDialogProvider();

  const [isLoading, setIsLoading] = useState(false);
  const [showNewUserAccountModal, setShowNewUserAccountModal] = useState(false);
  const [userAccounts, setUserAccounts] = useState([]);

  const [selectedUserAccount, setSelectedUserAccount] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);

  const toggleNewUserAccount = () => {
    setShowNewUserAccountModal(!showNewUserAccountModal);
  };

  const getUserAccounts = async () => {
    try {
      setIsLoading(true);
      const response = await protectedAPI.get("settings/userAccount/all");
      if (response.status === 200) {
        setUserAccounts(response.data);
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteUserAccount = async (userAccountId) => {
    try {
      const response = await protectedAPI.delete(
        "settings/userAccount/delete/" + userAccountId
      );
      if (response.status === 200) {
        getUserAccounts();
        showToast({
          type: "success",
          detail: response.data.message,
        });
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };

  const deleteUserAccountConfirm = (userAccountId) => {
    showConfirmDialog({
      message: "Are you sure you want to delete this user account?",
      onAccept: () => deleteUserAccount(userAccountId),
    });
  };

  const openEditModal = (userAccount) => {
    setSelectedUserAccount(userAccount);
    setIsUpdate(true);
    toggleNewUserAccount();
  };

  const updateAccess = async (userAccount) => {
    try {
      const response = await protectedAPI.post(
        "settings/userAccount/updateAccess/" + userAccount.id,
        {
          access: userAccount.access === "1" ? "0" : "1",
        }
      );
      if (response.status === 200) {
        getUserAccounts();
        showToast({
          type: "success",
          detail: response.data.message,
        });
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };

  const employeeTemplate = (userAccount) => {
    return (
      <>
        <div className="d-flex flex-column">
          {userAccount.nickname !== null ? (
            <>
              <span className="fw-bold">{userAccount.nickname}</span>
              <small>{userAccount.fullname}</small>
            </>
          ) : (
            <small className="text-danger fw-light">Deleted Employee</small>
          )}
        </div>
      </>
    );
  };

  const accessTemplate = (userAccount) => {
    return (
      <>
        <InputSwitch
          checked={userAccount.access === "1"}
          onChange={() => updateAccess(userAccount)}
        />
      </>
    );
  };

  const usernameTemplate = (userAccount) => {
    return (
      <>
        <span
          className="badge text-bg border border-dark-subtle fw-bold"
          style={{ fontSize: "12px" }}
        >
          {userAccount.username}
        </span>
      </>
    );
  };

  const rowNoTemplate = (data, options) => {
    return options.rowIndex + 1;
  };

  const actionTemplate = (userAccount) => {
    return (
      <>
        <div className="btn-group" role="group">
          <button
            type="button"
            className="btn btn-sm bg-transparent border"
            onClick={() => openEditModal(userAccount)}
          >
            <i className="pi pi-pencil"></i>
          </button>
          <button
            type="button"
            className="btn btn-sm bg-transparent border text-danger"
            onClick={() => deleteUserAccountConfirm(userAccount.id)}
          >
            <i className="pi pi-trash"></i>
          </button>
        </div>
      </>
    );
  };

  const userAccountTableData = {
    fetchData: getUserAccounts,
    records: userAccounts,
    exportColumns: [
      { header: "ID", field: "id" },
      { header: "Group", field: "groupName" },
      { header: "Nickname", field: "nickname" },
      { header: "Full Name", field: "fullname" },
      { header: "EMP No.", field: "empNo" },
      { header: "Username", field: "username" },
      { header: "Access", field: "access" },
    ],
    tableColumns: [
      { header: "#", body: rowNoTemplate, style: { width: "50px" } },
      { header: "Employee", body: employeeTemplate },
      { header: "Nickname", field: "nickname", style: { display: "none" } },
      { header: "Full Name", field: "fullname", style: { display: "none" } },
      { header: "Group", field: "groupName" },
      { header: "EMP No.", field: "empNo" },
      { header: "_Username", field: "username", style: { display: "none" } },
      { header: "Username", body: usernameTemplate },
      { header: "Access", body: accessTemplate },
      { header: "", body: actionTemplate, style: { width: "100px" } },
    ],
    globalFilterFields: [
      "nickname",
      "fullname",
      "groupName",
      "empNo",
      "username",
      "access",
    ],
    dataKey: "id",
  };

  useEffect(() => {
    getUserAccounts();
  }, []);

  return (
    <div className="d-flex main-content">
      <MenuBar />
      <div className="d-flex flex-column page">
        <Header pageTitle="User Accounts" />
        <div className="d-flex flex-column p-4">
          <div className="d-flex">
            <Button
              variant="info"
              onClick={() => {
                setSelectedUserAccount(null);
                setIsUpdate(false);
                toggleNewUserAccount();
              }}
            >
              <i className="pi pi-plus me-1"></i> New User Account
            </Button>
          </div>
          <div className="mt-3">
            {!isLoading ? (
              <Card className="border">
                <CustomDataTable
                  records={userAccountTableData.records}
                  fetchData={userAccountTableData.fetchData}
                  exportColumns={userAccountTableData.exportColumns}
                  tableColumns={userAccountTableData.tableColumns}
                  globalFilterFields={userAccountTableData.globalFilterFields}
                  dataKey={userAccountTableData.dataKey}
                />
              </Card>
            ) : (
              <div className="d-flex flex-column justify-content-center text-center mt-5">
                <ProgressSpinner style={{ width: "50px", height: "50px" }} />
                <span>Just a moment...</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <NewUserAccount
        show={showNewUserAccountModal}
        toggle={toggleNewUserAccount}
        getAccounts={getUserAccounts}
        isUpdate={isUpdate}
        selectedUserAccount={selectedUserAccount}
      />
    </div>
  );
}

export default UserAccount;
