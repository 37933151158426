import { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import MenuBar from "../../components/Menubar/MenuBar";
import { useToastProvider } from "../../providers/useToastProvider";
import { Button } from "react-bootstrap";
import protectedAPI from "../../config/protectedAPI";
import CustomDataTable from "../../components/Tables/CustomDataTable";
import { ProgressSpinner } from "primereact/progressspinner";
import NewMenuLIst from "../../components/Settings/NewMenuLIst";
import { Card } from "primereact/card";
import { useMenuProvider } from "../../providers/useMenuProvider";
import { useConfirmDialogProvider } from "../../providers/useConfirmDialogProvider";

function MenuList() {
  // const navigate = useNavigate();
  const showToast = useToastProvider();
  const showConfirmDialog = useConfirmDialogProvider();
  const { getMenuItems } = useMenuProvider();

  const [isLoading, setIsLoading] = useState(false);
  const [showNewMenuListModal, setShowNewMenuListModal] = useState(false);
  const [menuLists, setMenuLists] = useState([]);

  const [selectedMenuList, setSelectedMenuList] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);

  const toggleNewMenuList = () => {
    setShowNewMenuListModal(!showNewMenuListModal);
  };

  const getMenuLists = async () => {
    try {
      setIsLoading(true);
      const response = await protectedAPI.get("settings/menuList/all");
      if (response.status === 200) {
        setMenuLists(response.data);
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteMenuList = async (menuListId) => {
    try {
      const response = await protectedAPI.delete(
        "settings/menuList/delete/" + menuListId
      );
      if (response.status === 200) {
        getMenuItems();
        getMenuLists();
        showToast({
          type: "success",
          detail: response.data.message,
        });
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };

  const deleteMenuListConfirm = (menuListId) => {
    showConfirmDialog({
      message: "Are you sure you want to delete this menu list?",
      onAccept: () => deleteMenuList(menuListId),
    });
  };

  const openEditModal = (menuList) => {
    setSelectedMenuList(menuList);
    setIsUpdate(true);
    toggleNewMenuList();
  };

  const rowNoTemplate = (data, options) => {
    return options.rowIndex + 1;
  };

  const urlTemplate = (item) => {
    return (
      <>
        <span
          className="badge text-bg border border-dark-subtle fw-normal"
          style={{ fontSize: "12px" }}
        >
          {item.url}
        </span>
      </>
    );
  };

  const isMenuTemplate = (item) => {
    return (
      <>
        <span
          className={`badge fw-bold ${
            item.isMenuItem == 1 ? "bg-success" : "bg-danger"
          }`}
          style={{ fontSize: "10px" }}
        >
          {item.isMenuItem == 1 ? "Yes" : "No"}
        </span>
      </>
    );
  };

  const actionTemplate = (menuList) => {
    return (
      <>
        <div className="btn-group" role="group">
          <button
            type="button"
            className="btn btn-sm bg-transparent border"
            onClick={() => openEditModal(menuList)}
          >
            <i className="pi pi-pencil"></i>
          </button>
          <button
            type="button"
            className="btn btn-sm bg-transparent border text-danger"
            onClick={() => deleteMenuListConfirm(menuList.id)}
          >
            <i className="pi pi-trash"></i>
          </button>
        </div>
      </>
    );
  };

  const menuListTableData = {
    fetchData: getMenuLists,
    records: menuLists,
    exportColumns: [
      { header: "ID", field: "id" },
      { header: "Category", field: "categoryName" },
      { header: "Name", field: "name" },
      { header: "Url", field: "url" },
      { header: "order", field: "order" },
    ],
    tableColumns: [
      { header: "#", body: rowNoTemplate, style: { width: "50px" } },
      { header: "Category", field: "categoryName", sortable: true },
      { header: "Name", field: "name", sortable: true },
      { header: "_Url", field: "url", style: { display: "none" } },
      { header: "Url", body: urlTemplate },
      { header: "Menu Item", body: isMenuTemplate },
      { header: "Order", field: "order", sortable: true },
      { header: "", body: actionTemplate, style: { width: "100px" } },
    ],
    globalFilterFields: ["categoryName", "name", "url", "order"],
    dataKey: "id",
  };

  useEffect(() => {
    getMenuLists();
  }, []);

  return (
    <div className="d-flex main-content">
      <MenuBar />
      <div className="d-flex flex-column page">
        <Header pageTitle="Menu Lists" />
        <div className="d-flex flex-column p-4">
          <div className="d-flex">
            <Button
              variant="info"
              onClick={() => {
                setSelectedMenuList(null);
                setIsUpdate(false);
                toggleNewMenuList();
              }}
            >
              <i className="pi pi-plus me-1"></i> New Menu Item
            </Button>
          </div>
          <div className="mt-3">
            {!isLoading ? (
              <Card className="border">
                <CustomDataTable
                  records={menuListTableData.records}
                  fetchData={menuListTableData.fetchData}
                  exportColumns={menuListTableData.exportColumns}
                  tableColumns={menuListTableData.tableColumns}
                  globalFilterFields={menuListTableData.globalFilterFields}
                  dataKey={menuListTableData.dataKey}
                />
              </Card>
            ) : (
              <div className="d-flex flex-column justify-content-center text-center mt-5">
                <ProgressSpinner style={{ width: "50px", height: "50px" }} />
                <span>Just a moment...</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <NewMenuLIst
        show={showNewMenuListModal}
        toggle={toggleNewMenuList}
        getMenuLists={getMenuLists}
        isUpdate={isUpdate}
        selectedMenuList={selectedMenuList}
      />
    </div>
  );
}

export default MenuList;
