import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { UPLOADS_URL } from "../../config/api";
import { Avatar } from "primereact/avatar";

function ViewAssignees(props) {
  const {
    header,
    viewAssgineeDialogVisible,
    setViewAssigneesDialogVisible,
    assignees,
  } = props;

  const [assigneeList, setAssigneeList] = useState([]);

  useEffect(() => {
    setAssigneeList(assignees);
  }, [assignees]);

  return (
    <>
      <Dialog
        header={header}
        visible={viewAssgineeDialogVisible}
        style={{ width: "50vw" }}
        onHide={() => {
          setViewAssigneesDialogVisible(!viewAssgineeDialogVisible);
        }}
      >
        <div className="d-flex flex-column">
          {!assigneeList || assigneeList.length === 0 ? (
            <div className="alert alert-danger" role="alert">
              <span>No assignees found for this project</span>
            </div>
          ) : (
            <div className="d-flex flex-column">
              {assigneeList.map((assignee, index) => (
                <div className="alert border d-flex">
                  <div className="me-4">
                    <Avatar
                      key={index}
                      image={
                        UPLOADS_URL +
                        "employees/" +
                        (assignee.userDetails.image || "no-user.png")
                      }
                      size="large"
                      shape="circle"
                    />
                  </div>
                  <div className="d-flex flex-column">
                    <div>
                      <span className="fw-bold">
                        {assignee.userDetails.fullname ||
                          assignee.userDetails.nickname}
                      </span>
                    </div>
                    <div className="d-flex">
                      <small className="me-4">
                        Email : {assignee.userDetails?.email || "-"}
                      </small>
                      <small>
                        Mobile: {assignee.userDetails?.mobile || "-"}
                      </small>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
}

export default ViewAssignees;
