import React, { useCallback, useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button, Form } from "react-bootstrap";
import protectedAPI from "../../config/protectedAPI";
import { useToastProvider } from "../../providers/useToastProvider";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";

function AssignProjectManager(props) {
  const { show, toggle, _selectedProject, reloadProjects } = props;
  const showToast = useToastProvider();

  const [projects, setProjects] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState(null);

  const selectedProjectRef = useRef(null);
  const selectedUserRef = useRef(null);
  const saveBtnRef = useRef(null);

  const saveModule = async () => {
    try {
      if (!selectedProject || !selectedUsers) {
        showToast({
          type: "warn",
          detail: "Project and Manager are required.",
        });
        return;
      }

      saveBtnRef.current.disabled = true;
      saveBtnRef.current.innerText = "Please wait...";

      const _selectedUserIds = selectedUsers.map((user) => user.id);

      const url = "project/assignProjectManager/" + selectedProject.id;
      const response = await protectedAPI.post(url, {
        userIds: JSON.stringify(_selectedUserIds),
      });
      if (response.status === 201 || response.status === 200) {
        reloadProjects();
        showToast({
          type: "success",
          detail: response.data.message,
        });
        clearFields();
        toggle();
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    } finally {
      saveBtnRef.current.disabled = false;
      saveBtnRef.current.innerText = "Save";
    }
  };

  const clearFields = () => {
    setSelectedProject(null);
    setSelectedUsers(null);
  };

  const getProjects = async () => {
    try {
      const response = await protectedAPI.get("project/all");
      if (response.status === 200) {
        setProjects(response.data);
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };

  const getUsers = async () => {
    try {
      const response = await protectedAPI.get("project/getProjectManagers");
      if (response.status === 200) {
        setUsers(response.data);
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };

  useEffect(() => {
    clearFields();
    getProjects();
    getUsers();
  }, []);

  useEffect(() => {
    if (_selectedProject) {
      setSelectedProject(_selectedProject);
      const _preAssignedUsers = _selectedProject?.projectManagers?.map(
        ({ userDetails }) => userDetails
      );
      if (_preAssignedUsers) {
        setSelectedUsers(_preAssignedUsers);
      }
    }
  }, [_selectedProject]);

  return (
    <>
      <Dialog
        header="Assign Project Managers"
        visible={show}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => {
          toggle();
        }}
      >
        <div className="row">
          <div className="col-md-12">
            <Form.Group className="d-flex flex-column mb-3">
              <Form.Label htmlFor="projectId">Project</Form.Label>
              <Dropdown
                ref={selectedProjectRef}
                value={selectedProject}
                onChange={(e) => setSelectedProject(e.value)}
                options={projects}
                filter
                optionLabel="title"
                placeholder="Select a Project"
                className="w-full md:w-14rem"
              />
            </Form.Group>
          </div>
          <div className="col-md-12">
            <Form.Group className="d-flex flex-column mb-3">
              <Form.Label htmlFor="userId">Project Manager</Form.Label>
              <MultiSelect
                ref={selectedUserRef}
                value={selectedUsers}
                onChange={(e) => setSelectedUsers(e.value)}
                options={users}
                filter
                optionLabel={(user) => user.fullname || user.nickname}
                placeholder="Select a User"
                className="w-full md:w-14rem"
              />
            </Form.Group>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12 d-flex justify-content-end">
            <Button
              variant="info"
              className="p-2 px-4"
              ref={saveBtnRef}
              onClick={saveModule}
            >
              Save
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default AssignProjectManager;
