import React, { useState } from "react";
import MobileMenuBar from "../Menubar/MobileMenuBar";
import { FaBars } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useConfirmDialogProvider } from "../../providers/useConfirmDialogProvider";
import { useToastProvider } from "../../providers/useToastProvider";
import { Sidebar } from "primereact/sidebar";
import { useThemeProvider } from "../../providers/useThemeProvider";

function Header(props) {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [notificationVisible, setNotificationVisible] = useState(false);

  const navigate = useNavigate();
  const showConfirmDialog = useConfirmDialogProvider();
  const showToast = useToastProvider();
  const { appTheme, toggleTheme } = useThemeProvider();

  const logout = () => {
    showConfirmDialog({
      message: "You want to logout from the system.",
      icon: "pi pi-power-off text-danger fs-1",
      onAccept: function () {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        navigate("/");
        showToast({
          type: "success",
          detail: "You have successfully logged out.",
        });
      },
    });
  };

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  return (
    <>
      <div className="d-flex justify-content-between border-bottom p-2 header shadow-sm">
        <div className="d-flex justify-content-start align-items-center">
          <div className="ms-3">
            <button
              className="btn btn-sm border fs-6"
              onClick={toggleMobileMenu}
            >
              <FaBars />
            </button>
          </div>
          <div style={{ paddingTop: "10px" }} className="d-none d-sm-block ms-md-3">
            <h4 className={"fw-bold " + props.className}>
              {props.pageTitle ? props.pageTitle : "Dashboard"}
            </h4>
          </div>
        </div>
        <div className="d-flex align-items-center pe-3">
          <button
            className="btn header-btn me-2 pt-2"
            onClick={() => toggleTheme()}
          >
            <i
              className={appTheme === "light" ? "pi pi-moon" : "pi pi-sun"}
            ></i>
          </button>
          <button
            className="btn header-btn me-2 pt-2"
            onClick={() => setNotificationVisible(true)}
          >
            <i className="pi pi-bell"></i>
          </button>
          <button
            className="btn header-btn me-2 pt-2"
            onClick={() => navigate("/user/account")}
          >
            <i className="pi pi-user"></i>
          </button>
          <button className="btn header-btn pt-2" onClick={logout}>
            <i className="pi pi-power-off"></i>
          </button>
        </div>
      </div>
      <Sidebar
        visible={notificationVisible}
        position="right"
        onHide={() => setNotificationVisible(false)}
        header="Notifications"
      >
        <div className="d-flex flex-column border-top py-3">
          <small className="text-secondary">No notifications available.</small>
        </div>
      </Sidebar>
      <MobileMenuBar show={showMobileMenu} toggle={toggleMobileMenu} />
    </>
  );
}

export default Header;
