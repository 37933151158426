import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";

function ViewScreenshot(props) {
  const {
    viewScreenshotDialogVisible,
    setViewScreenshotDialogVisible,
    screenshotImage,
  } = props;

  const [downloadedScreenshot, setDownloadedScreenshot] = useState(null);

  useEffect(() => {
    setDownloadedScreenshot(screenshotImage);
  }, [screenshotImage]);

  return (
    <>
      <Dialog
        header={downloadedScreenshot?.filename}
        visible={viewScreenshotDialogVisible}
        style={{ width: "75vw" }}
        onHide={() => {
          setViewScreenshotDialogVisible(!viewScreenshotDialogVisible);
        }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 d-flex flex-column">
              <img
                src={"data:image/png;base64," + downloadedScreenshot?.image}
                alt="Captured"
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default ViewScreenshot;
