import React, { createContext, useCallback, useContext, useState } from "react";
import protectedAPI from "../config/protectedAPI";
import { useToastProvider } from "./useToastProvider";
import { useNavigate } from "react-router-dom";

const MenuContext = createContext();

export const MenuProvider = ({ children }) => {
  const [menuItems, setMenuItems] = useState([]);
  const [privilegedViews, setPrivilegedViews] = useState([]);
  const [employee, setEmployee] = useState(null);
  const [groupProfile, setGroupProfile] = useState(null);
  const showToast = useToastProvider();
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem("user"));

  const getMenuItems = async () => {
    if (user) {
      try {
        const response = await protectedAPI.get(
          "settings/groupPrivilege/getAllByGroup/" + user.groupId
        );
        if (response.status === 200) {
          setMenuItems(response.data?.privileges);
          setEmployee(response.data?.employee);
          setGroupProfile(response.data?.groupProfile);
        }
      } catch (error) {
        showToast({
          type: error.response?.status < 500 ? "warn" : "error",
          detail:
            error.response?.data?.message ||
            "An error occurred. Please try again.",
        });
      }
      try {
        const response = await protectedAPI.get(
          "settings/userPrivilege/privilegedViews/" + user.id
        );
        if (response.status === 200) {
          const urls = response.data.map((view) => view.url);
          setPrivilegedViews(urls);
          localStorage.setItem("privilegedViews", JSON.stringify(urls));
        }
      } catch (error) {
        showToast({
          type: error.response?.status < 500 ? "warn" : "error",
          detail:
            error.response?.data?.message ||
            "An error occurred. Please try again.",
        });
      }
    } else {
      localStorage.setItem(
        "logout_msg",
        "You have been logged out. Please log in again."
      );
      navigate("/");
    }
  };

  return (
    <MenuContext.Provider
      value={{
        menuItems,
        privilegedViews,
        getMenuItems,
        employee,
        groupProfile,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export const useMenuProvider = () => useContext(MenuContext);
