import React from "react";
import { Link } from "react-router-dom";

function MenuCategorySubItem(props) {
  return (
    <>
      {/* map props.items */}
      <div className="d-flex flex-column">
        {props.items.map(
          (item, index) =>
            item.isMenuItem == 1 && (
              <Link
                key={index}
                to={item.url}
                className="btn menu-subcategory-item"
              >
                <div className="d-flex align-items-center">
                  <span
                    className="ms-3"
                    style={{ fontWeight: "600", fontSize: "13px" }}
                  >
                    <i className="pi pi-angle-right me-2"></i> {item.label}
                  </span>
                </div>
              </Link>
            )
        )}
      </div>
    </>
  );
}

export default MenuCategorySubItem;
