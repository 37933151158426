import React, { useCallback, useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button, Form } from "react-bootstrap";
import protectedAPI from "../../config/protectedAPI";
import { useToastProvider } from "../../providers/useToastProvider";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";

function AssignModuleTester(props) {
  const { show, toggle, _selectedModule, reloadModules } = props;
  const showToast = useToastProvider();
         
  const [modules, setModules] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState(null);

  const selectedModuleRef = useRef(null);
  const selectedUserRef = useRef(null);
  const saveBtnRef = useRef(null);

  const saveModule = async () => {
    try {
      if (!selectedModule || !selectedUsers) {
        showToast({
          type: "warn",
          detail: "Module and Tester are required.",
        });
        return;
      }

      saveBtnRef.current.disabled = true;
      saveBtnRef.current.innerText = "Please wait...";

      const _selectedUserIds = selectedUsers.map((user) => user.id);

      const url = "module/assignModuleTester/" + selectedModule.id;
      const response = await protectedAPI.post(url, {
        userIds: JSON.stringify(_selectedUserIds),
      });
      if (response.status === 201 || response.status === 200) {
        reloadModules();
        showToast({
          type: "success",
          detail: response.data.message,
        });
        clearFields();
        toggle();
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    } finally {
      saveBtnRef.current.disabled = false;
      saveBtnRef.current.innerText = "Save";
    }
  };

  const clearFields = () => {
    setSelectedModule(null);
    setSelectedUsers(null);
  };

  const getModules = async () => {
    try {
      const response = await protectedAPI.get("module/all");
      if (response.status === 200) {
        setModules(response.data);
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };

  const getUsers = async () => {
    try {
      const response = await protectedAPI.get("module/getAvailableTesters");
      if (response.status === 200) {
        setUsers(response.data);
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };

  useEffect(() => {
    clearFields();
    getModules();
    getUsers();
  }, []);

  useEffect(() => {
    if (_selectedModule) {
      setSelectedModule(_selectedModule);
      const _preAssignedUsers = _selectedModule?.moduleTesters?.map(
        ({ userDetails }) => userDetails
      );
      if (_preAssignedUsers) {
        setSelectedUsers(_preAssignedUsers);
      }
    }
  }, [_selectedModule]);

  return (
    <>
      <Dialog
        header="Assign Module Tester"
        visible={show}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => {
          toggle();
        }}
      >
        <div className="row">
          <div className="col-md-12">
            <Form.Group className="d-flex flex-column mb-3">
              <Form.Label htmlFor="moduleId">Module</Form.Label>
              <Dropdown
                ref={selectedModuleRef}
                value={selectedModule}
                onChange={(e) => setSelectedModule(e.value)}
                options={modules}
                filter
                optionLabel="title"
                placeholder="Select a Module"
                className="w-full md:w-14rem"
              />
            </Form.Group>
          </div>
          <div className="col-md-12">
            <Form.Group className="d-flex flex-column mb-3">
              <Form.Label htmlFor="userId">Module Tester</Form.Label>
              <MultiSelect
                ref={selectedUserRef}
                value={selectedUsers}
                onChange={(e) => setSelectedUsers(e.value)}
                options={users}
                filter
                optionLabel={(user) => user.fullname || user.nickname}
                placeholder="Select a User"
                className="w-full md:w-14rem"
              />
            </Form.Group>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12 d-flex justify-content-end">
            <Button
              variant="info"
              className="p-2 px-4"
              ref={saveBtnRef}
              onClick={saveModule}
            >
              Save
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default AssignModuleTester;
