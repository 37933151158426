import * as XLSX from "xlsx";
import { Document, Packer, Paragraph, TextRun, ImageRun } from "docx";
import { saveAs } from "file-saver";
import { Buffer } from "buffer";
import protectedAPI from "../../config/protectedAPI";

/**
    {
      #: 1,
      projectCode: 1,
      projectTitle: "Project Alpha",
      moduleCode: 1,
      moduleTitle: "Authentication",
      riskCategoryTitle: "Security",
      ref: "AUTH-001",
      possibleRisk: "Unauthorized access",
      severity: "high",
      observations: "Weak password policy",
      implications: "Potential data breach",
      recommendations: "Implement strong password requirements",
      managerAccepted: Yes,
      directorAccepted: Yes,
    },
 */

const getTimeStamp = async () => {
  return new Date().getTime().toString().replace(/:/g, "_");
};

const exportTestcasesToExcel = async (testcases) => {
  try {
    if (testcases) {
      let _testcases = [];
      testcases?.map((testcase, index) => {
        if (testcase.ref != null) {
          _testcases.push({
            "#": index + 1,
            "Project Code": testcase.projectCode,
            "Project Title": testcase.projectTitle,
            "Module Code": testcase.moduleCode,
            "Module Title": testcase.moduleTitle,
            "Risk Category": testcase.riskCategoryTitle,
            Reference: testcase.ref,
            "Possible Risk": testcase.possibleRisk,
            "Risk Level":
              testcase.severity == 1
                ? "Low"
                : testcase.severity == 2
                ? "Medium"
                : "High",
            Observations: testcase.observations,
            Implications: testcase.implications,
            Recommendations: testcase.recommendations,
            "Manager Accepted": testcase.managerAccepted == 1 ? "Yes" : "No",
            "Director Accepted": testcase.directorAccepted == 1 ? "Yes" : "No",
          });
        }
      });
      const worksheet = XLSX.utils.json_to_sheet(_testcases);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Testcases");
      const timestamp = await getTimeStamp();
      XLSX.writeFile(workbook, `testcases_${timestamp}.xlsx`);
    }
  } catch (e) {
    console.log(e);
  }
};

const fetchScreenshots = async (testcase) => {
  try {
    const response = await protectedAPI.get(
      "screenshot/getScreenshotsByTestcaseId/" + testcase.id
    );
    if (response.status === 200) {
      const screenshots = response.data;
      await Promise.all(
        screenshots.map(async (screenshot) => {
          const ssResponse = await protectedAPI.get(
            "screenshot/downloadScreenshot/" + screenshot.id
          );
          if (ssResponse.status === 200) {
            screenshot.base64 = ssResponse.data.base64;
          }
        })
      );
      testcase.screenshots = screenshots;
    } else {
      testcase.screenshots = [];
    }
  } catch (error) {
    testcase.screenshots = [];
  }
};

const fetchAllScreenshots = async (testcases) => {
  await Promise.all(testcases.map(fetchScreenshots));
};

const exportTestcasesToWord = async (testcases) => {
  try {
    testcases = testcases.filter((testcase) => testcase.ref);
    await fetchAllScreenshots(testcases);
    const doc = new Document({ sections: [] });

    testcases?.forEach((testcase, index) => {
      doc.addSection({
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: `${index + 1}. `,
                size: 24,
              }),
              new TextRun({
                text: `Reference: ${testcase.ref}`,
                bold: true,
                size: 24,
              }),
            ],
            heading: "Heading1",
            spacing: {
              line: 1.5 * 240,
            },
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: `Project: `,
                size: 24,
              }),
              new TextRun({
                text: `${testcase.projectCode} - ${testcase.projectTitle}`,
                size: 24,
                bold: true,
              }),
            ],
            spacing: {
              line: 1.5 * 240,
            },
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: `Module: `,
                size: 24,
              }),
              new TextRun({
                text: `${testcase.moduleCode} - ${testcase.moduleTitle}`,
                size: 24,
                bold: true,
              }),
            ],
            spacing: {
              line: 1.5 * 240,
            },
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: `Possible Risk: `,
                size: 24,
              }),
              new TextRun({
                text: `${testcase.possibleRisk || "Unknown Risk"}`,
                size: 24,
                bold: true,
              }),
            ],
            spacing: {
              line: 1.5 * 240,
            },
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: `Observations: `,
                size: 24,
              }),
              new TextRun({
                text: `${testcase.observations || "No Observations"}`,
                size: 24,
                bold: true,
              }),
            ],
            spacing: {
              line: 1.5 * 240,
            },
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: `Implications: `,
                size: 24,
              }),
              new TextRun({
                text: `${testcase.implications || "No Implications"}`,
                size: 24,
                bold: true,
              }),
            ],
            spacing: {
              line: 1.5 * 240,
            },
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: `Recommendations: `,
                size: 24,
              }),
              new TextRun({
                text: `${testcase.recommendations || "No Recommendations"}`,
                size: 24,
                bold: true,
              }),
            ],
            spacing: {
              line: 1.5 * 240,
            },
          }),
          ...testcase.screenshots
            ?.filter((screenshot) => screenshot?.base64)
            .map((screenshot) => {
              return [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: ``,
                      size: 24,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: ``,
                      size: 24,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: `Screenshot: `,
                      size: 24,
                    }),
                    new TextRun({
                      text: `${screenshot.filename}`,
                      size: 24,
                      bold: true,
                    }),
                  ],
                  spacing: {
                    line: 1.5 * 240,
                  },
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: `Comments: `,
                      size: 24,
                    }),
                    new TextRun({
                      text: `${screenshot.comment || "No Comments"}`,
                      size: 24,
                      bold: true,
                    }),
                  ],
                  spacing: {
                    line: 1.5 * 240,
                  },
                }),
                new Paragraph({
                  children: [
                    new ImageRun({
                      data: Buffer.from(screenshot.base64, "base64"),
                      transformation: {
                        width: 600,
                        height: 400,
                      },
                    }),
                  ],
                }),
              ];
            })
            .flat(),
        ],
      });
    });

    Packer.toBlob(doc).then((blob) => {
      const timestamp = new Date().getTime().toString().replace(/:/g, "_");
      saveAs(blob, `testcases_${timestamp}.docx`);
      console.log("Document created successfully");
    });
  } catch (e) {
    console.log(e);
  }
};

export { exportTestcasesToExcel, exportTestcasesToWord };
