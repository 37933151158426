import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();
  return (
    <div
      className="d-flex main-content dark-gradient-bg"
    >
      <div className="d-flex flex-column justify-content-center align-items-center w-100">
        <i
          className="pi pi-exclamation-triangle text-danger mb-5"
          style={{ fontSize: "72px" }}
        ></i>
        <h1 className="fw-bold">Resource Not Found</h1>
        <span className="mb-5 text-wrap">
          The page you are looking for might have been removed or is temporarily
          unavailable.
        </span>
        <button className="btn btn-info" onClick={() => navigate("/dashboard")}>
          <i className="pi pi-home me-1"></i> Go to Home
        </button>
      </div>
    </div>
  );
}

export default NotFound;
