import React from "react";
import { Skeleton } from "primereact/skeleton";

function ActiveProjectsSkeleton() {
  return (
    <div>
      <div className="row">
        <div className="col-md-3">
          <Skeleton height="10rem"></Skeleton>
        </div>
        <div className="col-md-3">
          <Skeleton height="10rem"></Skeleton>
        </div>
        <div className="col-md-3">
          <Skeleton height="10rem"></Skeleton>
        </div>
        <div className="col-md-3">
          <Skeleton height="10rem"></Skeleton>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-3">
          <Skeleton height="10rem"></Skeleton>
        </div>
        <div className="col-md-3">
          <Skeleton height="10rem"></Skeleton>
        </div>
        <div className="col-md-3">
          <Skeleton height="10rem"></Skeleton>
        </div>
      </div>
    </div>
  );
}

export default ActiveProjectsSkeleton;
