import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import App from "./pages/App";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import "bootstrap/dist/css/bootstrap.min.css";
// import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "react-datepicker/dist/react-datepicker.css";
import "./css/custom.css";

import { PrimeReactProvider } from "primereact/api";
import { ToastProvider } from "./providers/useToastProvider";
import { MenuProvider } from "./providers/useMenuProvider";
import { ConfirmDialogProvider } from "./providers/useConfirmDialogProvider";
import { ThemeProvider } from "./providers/useThemeProvider";

import AllEmployee from "./pages/employees/AllEmployee";
import GroupProfile from "./pages/settings/GroupProfile";
import MenuCategory from "./pages/settings/MenuCategory";
import MenuList from "./pages/settings/MenuList";
import UserAccount from "./pages/settings/UserAccount";
import GroupPrivilege from "./pages/settings/GroupPrivilege";
import Forbidden from "./pages/auth/Forbidden";
import AllProjects from "./pages/masterFiles/AllProjects";
import NotFound from "./pages/NotFound";
import MyAccount from "./pages/MyAccount";
import AllModules from "./pages/masterFiles/AllModules";
import AllTestCases from "./pages/masterFiles/AllTestCases";
import AllRiskCat from "./pages/masterFiles/AllRiskCat";
import ViewProject from "./pages/masterFiles/ViewProject";

const auth = "/auth";
const user = "/user";
const masterFiles = "/master-files";
const projects = "/projects";
const risk = "/risk";
const modules = "/modules";
const testcases = "/testcases";
const settings = "/settings";

const isAuthenticated = () => {
  const user = localStorage.getItem("user");
  const accessToken = localStorage.getItem("accessToken");
  return user && accessToken;
};

const checkPrivilege = (url, privilegeList) => {
  return privilegeList.includes(url);
};

const ProtectedRoute = ({ children, path, dynamic }) => {
  const privilegedViews = JSON.parse(localStorage.getItem("privilegedViews"));
  if (!isAuthenticated()) {
    localStorage.setItem(
      "logout_msg",
      "We had to logged you out due to a SECURITY REASON. Please log in again."
    );
    return <Navigate to="/" />;
  }
  if (!privilegedViews || !checkPrivilege(path, privilegedViews)) {
    return <Navigate to="/auth/forbidden" />;
  }
  return children;
};

const AuthRoutes = () => [
  <Route key="login" exact path={`/`} element={<Login />} />,
  <Route
    key="register"
    exact
    path={`${auth}/register`}
    element={<Register />}
  />,
  <Route
    key="forbidden"
    exact
    path={`${auth}/forbidden`}
    element={<Forbidden />}
  />,
];

const UserRoutes = () => [
  <Route
    key="account"
    exact
    path={`${user}/account`}
    element={
      <ProtectedRoute path={`${user}/account`}>
        <MyAccount />
      </ProtectedRoute>
    }
  />,
];

const MasterFileRoutes = () => [
  <Route
    key="all"
    exact
    path={`${masterFiles}/employees`}
    element={
      <ProtectedRoute path={`${masterFiles}/employees`}>
        <AllEmployee />
      </ProtectedRoute>
    }
  />,
];

const ProjectRoutes = () => [
  <Route
    key="projects"
    exact
    path={`${projects}/all`}
    element={
      <ProtectedRoute path={`${projects}/all`}>
        <AllProjects />
      </ProtectedRoute>
    }
  />,
  <Route
    key="projects/view/:id"
    exact
    path={`${projects}/view/:id`}
    element={
      <ProtectedRoute path={`${projects}/view/:id`}>
        <ViewProject />
      </ProtectedRoute>
    }
  />,
];

const RiskRoutes = () => [
  <Route
    key="risk"
    exact
    path={`${risk}/all`}
    element={
      <ProtectedRoute path={`${risk}/all`}>
        <AllRiskCat />
      </ProtectedRoute>
    }
  />,
];

const ModuelsRoutes = () => [
  <Route
    key="modules"
    exact
    path={`${modules}/all`}
    element={
      <ProtectedRoute path={`${modules}/all`}>
        <AllModules />
      </ProtectedRoute>
    }
  />,
];

const TestCaseRoutes = () => [
  <Route
    key="testcases"
    exact
    path={`${testcases}/all`}
    element={
      <ProtectedRoute path={`${testcases}/all`}>
        <AllTestCases />
      </ProtectedRoute>
    }
  />,
];

const SettingsRoutes = () => [
  <Route
    key="group-profile"
    exact
    path={`${settings}/group-profile`}
    element={
      <ProtectedRoute path={`${settings}/group-profile`}>
        <GroupProfile />
      </ProtectedRoute>
    }
  />,
  <Route
    key="menu-category"
    exact
    path={`${settings}/menu-category`}
    element={
      <ProtectedRoute path={`${settings}/menu-category`}>
        <MenuCategory />
      </ProtectedRoute>
    }
  />,
  <Route
    key="menu-list"
    exact
    path={`${settings}/menu-list`}
    element={
      <ProtectedRoute path={`${settings}/menu-list`}>
        <MenuList />
      </ProtectedRoute>
    }
  />,
  <Route
    key="user-account"
    exact
    path={`${settings}/user-account`}
    element={
      <ProtectedRoute path={`${settings}/user-account`}>
        <UserAccount />
      </ProtectedRoute>
    }
  />,
  <Route
    key="group-privilege"
    exact
    path={`${settings}/group-privilege`}
    element={
      <ProtectedRoute path={`${settings}/group-privilege`}>
        <GroupPrivilege />
      </ProtectedRoute>
    }
  />,
];

const root = ReactDOM.createRoot(document.getElementById("root"));

const primeReactValue = {
  ripple: true,
};

root.render(
  <React.StrictMode>
    <PrimeReactProvider value={primeReactValue}>
      <ThemeProvider>
        <Router>
          <ToastProvider>
            <ConfirmDialogProvider>
              <MenuProvider>
                <Routes>
                  {/* PUBLIC */}
                  {AuthRoutes()}
                  {/* PROTECTED */}
                  <Route exact path="/dashboard" element={<App />} />
                  {UserRoutes()}
                  {MasterFileRoutes()}
                  {ProjectRoutes()}
                  {RiskRoutes()}
                  {ModuelsRoutes()}
                  {TestCaseRoutes()}
                  {SettingsRoutes()}
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </MenuProvider>
            </ConfirmDialogProvider>
          </ToastProvider>
        </Router>
      </ThemeProvider>
    </PrimeReactProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
